import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router-dom";

function LinkTab(props) {
  let history = useHistory();
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        history.push(props.href);
      }}
      {...props}
    />
  );
}

export default function NavTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let selectedColor = "rgb(207, 145, 147)";

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
        <LinkTab
          label="ГЛАВНАЯ"
          sx={{
            color: "rgba(0,0,0,0.6)",
            "&:hover": {
              color: (theme) =>
                theme?.palette?.custom?.tabTextHoverColor ?? "rgba(0,0,0,0.6)",
            },
            transition: "color 0.5s ease",
          }}
          href="/"
        />
        <LinkTab
          label="ПРАКТИКИ"
          sx={{
            color: "rgba(0,0,0,0.6)",
            "&:hover": {
              color: (theme) =>
                theme?.palette?.custom?.tabTextHoverColor ?? "rgba(0,0,0,0.6)",
            },
            transition: "color 0.5s ease",
          }}
          href="/techniques"
        />
        <LinkTab
          label="ПЕРЕД НАЧАЛОМ РАБОТЫ"
          sx={{
            color: "rgba(0,0,0,0.6)",
            "&:hover": {
              color: (theme) =>
                theme?.palette?.custom?.tabTextHoverColor ?? "rgba(0,0,0,0.6)",
            },
            transition: "color 0.5s ease",
          }}
          href="/before-start"
        />
        <LinkTab
          label="ОБО МНЕ"
          sx={{
            color: "rgba(0,0,0,0.6)",
            "&:hover": {
              color: (theme) =>
                theme?.palette?.custom?.tabTextHoverColor ?? "rgba(0,0,0,0.6)",
            },
            transition: "color 0.5s ease",
          }}
          href="/about"
        />
      </Tabs>
    </Box>
  );
}
